import './App.css';
// import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from "./components/home/Home"
import Contact from "./components/contact/contact"
import Datenschutz from "./components/datenschutz/Datenschutz"
import Imprint from "./components/imprint/Imprint"
import Product from "./components/product/Product"
import Page404 from "./components/404/404"

function App() {
  return (
    // <Router>
    //     <Switch>
    //       <Route path="/" exact component={Home} />
    //       {/* <Route path="/p=:product" exact component={Product} /> */}
    //     </Switch>
    //   </Router>

      <Router>
        <Routes>
          <Route path='/' element={<Home/>} />
          {/* <Route path="/" exact component={Home} /> */}
          {/* <Route path="/p=:product" exact component={Product} /> */}
          <Route path='/contact' element={<Contact/>} />
          <Route path="/legal-notice" element={<Imprint/>} />
          <Route path="/privacy-disclaimer" element={<Datenschutz/>} />
          <Route path="/privacy-disclaimer" element={<Datenschutz/>} />
          <Route path="/clothing/*" element={<Product/>} />
          <Route path="/home/*" element={<Product/>} />
          <Route path="*" element={<Page404/>} />
        </Routes>
      </Router>
  );
}

export default App;
