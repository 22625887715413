import React , {useEffect} from 'react'
import {Link} from 'react-router-dom'
import "./Header.css"
import NamirLogo from "../../assets/namir-logo.svg"


function Header(props) {

	// On Click
	const onLinkClick = () => {
		// dispatch(change(0))
		// dispatch(changeSiteNumber(1))
	}

	return(
		<div className="Header">
			<div className='content'>
				<div className="header-container">
					<div className="title-container">
						<Link className="logo" to="/">
						<img className='logo' src={NamirLogo}/>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Header