import './Product.css';
import React, {useEffect, useState} from 'react'
import NamirLogo from "../../assets/namir-logo.svg"
import AppPromoImage from "../../assets/namir-app-preview-iphones.png"
import Footer from "../footer/Footer"

const Product = () => {

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 935 ? true : false)

  // USE EFFECT
  useEffect(() => {
		document.title = "NAMIR - Luxury Fashion Discovery"
    window.scrollTo(0, 0)
	}, [])

	// Event Listener
	useEffect(() => {
		window.addEventListener("resize", handleWindowSize) 
		return () => window.removeEventListener("resize", handleWindowSize)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktop])

  // FUNCTIONS
	const handleWindowSize = () => {
		if (window.innerWidth > 935) {
			if (!isDesktop) setIsDesktop(true)
		} else {
			if (isDesktop) setIsDesktop(false)
		}
	}

  // UI
  const mobileView = () => {
    return (
      <div className='mobile-flex-container'>
        <img className='logo' src={NamirLogo}/>

        <img className='app-preview-image' src={AppPromoImage}/>

        <h1>NAMI<span>R</span></h1>

        <p className='subtitle'>SHOP YOUR STYLE</p>
        <p className='subtitle'>DISCOVER NEW UNDERRATED HIGH END BRANDS</p>
        <p className='subtitle'>FIND THE BEST PRICES</p>

        <a className="app-store-badge" href="https://apps.apple.com/de/app/namir/id1659295509" target="_blank" rel="noopener noreferrer"></a>
      </div>
    )
  }

  const desktopView = () => {
    return (
      <div className='content-flex-container'>
        <div className='split-container'>
          <div>
            <img className='logo' src={NamirLogo}/>
            <h1>NAMIR</h1>

            <p className='subtitle'>SHOP YOUR STYLE</p>
            <p className='subtitle'>DISCOVER NEW UNDERRATED HIGH END BRANDS</p>
            <p className='subtitle'>FIND THE BEST PRICES</p>

            <a className="app-store-badge" href="https://apps.apple.com/de/app/namir/id1659295509" target="_blank" rel="noopener noreferrer"></a>
          </div>
        </div>

        <div className='split-container' id='app-preview-container'>
            <img className='app-preview-image' src={AppPromoImage}/>
          </div>
      </div>
    )
  }

  return (
    <div className="Home">
      <div className='content'>
        {isDesktop ? desktopView() : mobileView()}
      </div>

      <Footer/>
    </div>
  );
}

export default Product;
