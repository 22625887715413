import React , {useEffect} from 'react'
import "./contact.css"
import Header from "../header/Header"
import Footer from "../footer/Footer"


function Contact() {

	useEffect(() => {
		document.title = "NAMIR - Contact"
	}, [])

	return(
		<div className="Contact">
			<Header/>

			<div className="contact-container">
				<a href="mailto:hello@namir-fashion.com">HELLO@NAMIR-FASHION.COM</a>
			</div>

			<Footer/>
		</div>
	)
}

export default Contact