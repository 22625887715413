import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import "./Footer.css"

function Footer() {

	const [isDesktop, setIsDesktop] = useState(window.innerWidth > 935 ? true : false)

	// Event Listener
	useEffect(() => {
		window.addEventListener("resize", handleWindowSize) 
		return () => window.removeEventListener("resize", handleWindowSize)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktop])

  	// FUNCTIONS
	const handleWindowSize = () => {
		if (window.innerWidth > 935) {
			if (!isDesktop) setIsDesktop(true)
		} else {
			if (isDesktop) setIsDesktop(false)
		}
	}

	// On Click
	const onLinkClick = () => {
		// dispatch(change(0))
		// dispatch(changeSiteNumber(1))
	}

	// UI
	// const mobileView = () => {
	// 	return (
		  
	// 	)
	//   }
	
	//   const desktopView = () => {
	// 	return (
		 
	// 	)
	//   }

	return(
		<div className="Footer">
			<div className='footer-content'>
				<div className="footer-container">
					<div className="links">
						<Link className="label" to="/contact" onClick={onLinkClick}>CONTACT</Link>
						<Link className="label" to="/privacy-disclaimer" onClick={onLinkClick}>PRIVACY DISCLAIMER</Link>
						<Link className="label" to="/legal-notice" onClick={onLinkClick}>LEGAL NOTICE</Link>
					</div>

					<a className='instagram' href='https://www.instagram.com/namir.fashion/' target="_blank" rel="noopener noreferrer" />
				</div>
			</div>
		</div>
	)
}

export default Footer